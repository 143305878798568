@import "../../styles/variables";
@import "../../styles/mixins";

.advantage {
    margin-top: 50px;

    .col-md-3 {
        margin-bottom: 20px;
    }

    &__box {
        border-radius: 10px;
        box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.22);
        height: 100%;

        &-text {
            padding: 25px 25px 0 30px;
            color: rgb(43, 43, 43);

            h2 {
                font-size: 26px;
            }

            p {
                font-size: 15px;
                line-height: 1.7;
            }
        }
    }
}
