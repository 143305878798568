@import "../../styles/variables";
@import "../../styles/mixins";

.contacts {
    padding-top: 0;

    &__header {
        margin-top: -80px;
        background-image: url("../../resources/img/Contacts/contacts.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        height: 500px;
        min-height: auto;

        .contacts__overlay {
            margin-top: 80px;
            background: #011627ab;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            h1 {
                font-size: 80px;
                font-weight: 700;
                color: #fff;
            }
        }
    }

    &__box {
        border: 1px solid #2b2b2b2b;
        margin-top: 40px!important;
        padding: 30px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        
        svg {
            margin: 0 auto;
            background-color: #ffffff14;
            border-radius: 50%;
            padding: 10px;
            width: 60px;
            height: 60px;
            color: #2b2b2b;
            font-size: 40px;
        }

        h3 {
            color: #2b2b2b;
            font-weight: 600;
            font-size: 25px;
            margin-bottom: 20px;
        }

        a {
            line-height: 1.6;
            color: #2b2b2b;

            &:hover {
                color: #75b31e;
            }
        }

        p{
            color: #2b2b2b;
        }
    }

    iframe {
        border: 1px solid #2b2b2b2b;
        margin: 50px 0;
    }

    @include mq("phone-wide") {
        &__header {
            .contacts__overlay {
                h1 {
                    font-size: 40px;
                }
            }
        }
    }

    @include mq("phone-wide") {
        &__title {
            font-size: 30px;
        }

        .line-header {
            margin-bottom: 50px;
        }

        &__text {
            padding: 20px;
        }
    }
}
