@import "../../styles/variables";
@import "../../styles/mixins";

.svgmap {
    overflow: hidden;
    h2 {
        margin: 60px 0 40px;
        justify-content: center;

        &::after {
            margin-left: 15px;
            display: block;
            content: "";
            width: 52px;
            height: 1px;
            background-color: #75b31e;
        }
    }

    h5 {
        color: #2b2b2b;
        cursor: pointer;
    }
    #myWindow {
        margin-top: 20px;
        overflow: hidden;
        margin-left: -700px;
        background: #d7deee;

        .Russian,
        .tajikistan,
        .kazakhstan,
        .belarus,
        .poland,
        .uzbekistan,
        .germany,
        .Lithuania {
            transition: 0.3s;
            fill: #cdcdcd;
            &:hover {
                fill: #31640c;
            }
        }
    }

    .russian__hover,
    .tajikistan__hover,
    .kazakhstan__hover,
    .belarus__hover,
    .poland__hover,
    .uzbekistan__hover,
    .germany__hover,
    .lithuania__hover {
        transition: 0.3s;
        fill: #31640c;
    }

    @include mq("desktop-small") {
        #myWindow {
            margin-left: -880px;
        }
    }

    @include mq("tablet-mid") {
        #myWindow {
            margin-left: -1080px;
        }
    }

    @include mq("tablet") {
        #myWindow {
            margin-left: -1000px;
            overflow: scroll;
        }
    }

    @include mq("phone-wide") {
        h2 {
            text-align: center;
            font-size: 22px !important;
        }
    }
}
