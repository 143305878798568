@import "../../styles/variables";
@import "../../styles/mixins";

.my-form {
    margin-top: 30px;

    h2 {
        margin: 22px 0;
        color: #2b2b2b;
        font-size: 42px;
    }
    .MuiInputLabel-root {
        color: #2b2b2b;
    }

    .Mui-focused,
    .MuiInput-input {
        color: #2b2b2b !important;
    }

    .MuiInput-root {
        color: #2b2b2b;
        padding-bottom: 5px;

        &::before {
            border-bottom: 1px solid #2b2b2b !important;
        }

        &::after {
            border-bottom: 2px solid #2b2b2b;
        }
    }
}
