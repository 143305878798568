@import "../../styles/variables";
@import "../../styles/mixins";

.aboutsection {
    margin-top: 50px;
    
    &__img1 {
        background-image: url("../../resources/img/About/small.jpg");
        background-position: center center;
        height: 460px;
        background-size: cover;
    }

    &__slider {
        overflow: hidden;
        img {
            object-fit: cover;
            width: 100%;
        }
    }

    &__counter {
        padding-top: 30px;
        white-space: nowrap;
        font-size: 34px;
        color: #75b31e;
    }

    &__name{
        color: rgb(43, 43, 43);
    }

    &__text {
        margin-bottom: 10px;
        h2 {
            margin: 22px 0;
            color: rgb(43, 43, 43);
            font-size: 42px;
        }

        p {
            letter-spacing: 0.6px;
            line-height: 1.8;
            font-weight: 400;
            color: rgb(43, 43, 43);
        }
    }

    @include mq("phone-wide") {
        &__counter {
            font-size: 26px;
        }

        &__slider {
            height: auto;
        }

        &__text {
           
            h2 {
                font-size: 32px;
            }
        }
    }
}
