@import "../../styles/variables";
@import "../../styles/mixins";

.footer {
    color: #fff;
    background-image: url("../../resources/img/footer.jpg");
    background-position: center center;
    background-size: cover;
    margin-top: auto;

    &__overlay {
        padding: 80px 0 10px;
        background-color: #0d1404d6;
    }

    img {
        width: 80px;
        object-fit: contain;
        margin-bottom: 20px;
    }

    h3 {
        font-size: 23px;
        color: rgb(235, 235, 235);
    }

    &__link {
        display: flex;
        flex-direction: column;

        a {
            margin-top: 9px;

            &:hover {
                color: $cTwo;
            }
        }
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        margin-top: 20px !important;
    }

    @include mq("tablet") {
        .col-md-4 {
            text-align: center;
            margin-bottom: 30px;

            &::after {
                content: "";
                display: block;
                height: 2px;
                background-color: #dcdcdc;
                width: 60px;
                margin: 25px auto 0;
            }
        }
    }

    @include mq("phone-wide") {
        &__bottom {
            margin-top: 40px !important;
            flex-direction: column;
            align-items: center;
            line-height: 2;
        }
    }
}
