@import "../../styles/variables";
@import "../../styles/mixins";

.partnerssection {
    h2 {
        color: #2b2b2b;
        justify-content: center;

        &::after {
            margin-left: 15px;
            display: block;
            content: "";
            width: 52px;
            height: 1px;
            background-color: #75b31e;
        }
    }

    &__box {
        padding: 40px !important;
        border: 1px solid rgba(255, 255, 255, 0.128);
        display: flex;
        height: 250px;
        max-height: 250px;
        align-items: center;
        justify-content: center;
        transition: 0.5s;

        img {
            filter: contrast(0);
            width: 100%;
            transition: 0.5s;
        }

        &:hover {
            background-color: #d7deee;
            
            img {
                filter: none;
            }
        }
    }
}
