@import "../../styles/variables";
@import "../../styles/mixins";

.intro {
    background-color: #e7eaf1;
    width: 100%;
    min-height: 80vh;
    height: auto;

    &__left {
        padding: 120px 0 0 0;

        span {
            color: rgb(43, 43, 43);
            font-weight: 400;
            font-size: 24px;
        }

        h1 {
            margin: 22px 0;
            color: rgb(43, 43, 43);
            font-size: 52px;
        }

        p {
            letter-spacing: 0.6px;
            line-height: 1.8;
            font-weight: 400;
            color: rgb(43, 43, 43);
        }
    }
    &__right {
        overflow: hidden;
        img {
            margin-top: 10px;
            object-fit: cover;
            max-height: 600px;
            height: auto;
        }
    }

    @include mq("phone-wide") {
        &__left {
            padding: 0;
            h1 {
                margin: 22px 0;
                font-size: 33px;
            }
        }

        &__right {
            img {
                max-height: 400px;
                object-position: center;
            }
        }
    }
}
