@import "../../styles/variables";
@import "../../styles/mixins";

.productsection {
    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h2 {
            margin: 22px 0;
            color: rgb(43, 43, 43);
            font-size: 42px;
        }
    }

    .productcol1 {
        margin-top: 120px;
    }

    .productcol2 {
        margin-top: 80px;
    }

    .productcol3 {
        margin-top: 40px;
    }

    &__box {
        img {
            height: auto;
            object-fit: cover;
            width: 100%;
            transition: 0.4s;

            &:hover {
                transform: translateY(-10px);
            }
        }

        p {
            font-size: 22px;
            margin-top: 7px;
            color: #2b2b2b;
        }
    }

    @include mq("tablet-mid") {
        &__title {
            flex-direction: column;
            align-items: flex-start;

            h2 {
                font-size: 32px;
            }
        }
        .productcol1 {
            margin-top: 20px;
        }

        .productcol2 {
            margin-top: 20px;
        }

        .productcol3 {
            margin-top: 20px;
        }
    }
}
