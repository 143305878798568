.tabs__component {
    h2 {
        margin: 30px 0 25px;
        justify-content: center;

        &::after {
            margin-left: 15px;
            display: block;
            content: "";
            width: 52px;
            height: 1px;
            background-color: #75b31e;
        }
    }

    .MuiButtonBase-root {
        color: #000;
        font-family: "Mons";
    }

    .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected,
    .css-1q2h7u5.Mui-selected,
    .MuiTab-root .MuiTab-textColorPrimary {
        color: #75b31e;
        font-family: "Mons";
    }

    .MuiTabs-indicator {
        background-color: #75b31e;
    }

    img {
        width: 100%;
        object-fit: cover;
        height: 300px;
    }
}
