@import "../../styles/variables";
@import "../../styles/mixins";

.products {
    padding-top: 0;

    h4 {
        font-weight: 500;
        line-height: 1.4;
        color: #2b2b2b;
    }

    &__header {
        margin-top: -80px;
        background-image: url("../../resources/img/ProductsPage/products.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        height: 500px;
        min-height: auto;

        .products__overlay {
            margin-top: 80px;
            background: #011627ab;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            h1 {
                font-size: 80px;
                font-weight: 700;
                color: #fff;
            }
        }
    }

    &__title {
        color: #2b2b2b;
        font-size: 35px;
        margin-bottom: 30px;
    }

    &__text {
        font-size: 18px;
        color: #2b2b2b;
        line-height: 1.7;
        padding: 50px;
    }

    &__img {
        padding-bottom: 30px;
        img {
            object-fit: contain;
            object-position: center;
            height: 500px;
            width: 100%;
        }
    }
    
    .line-header {
        margin-bottom: 100px;
    }


    @include mq("phone-wide") {
        &__header {
            .products__overlay {
                h1 {
                    font-size: 43px;
                }
            }
        }

        &__title {
            font-size: 30px;
        }

        .line-header {
            margin-bottom: 50px;
        }

        &__text {
            padding: 20px;
        }
    }
}

