@import "../../styles/variables";
@import "../../styles/mixins";

.about {
    padding-top: 0;
   
    &__header {
        margin-top: -80px;
        background-image: url("../../resources/img/About/aboutbg.jpg");
        background-position: bottom;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        height: 500px;
        min-height: auto;

        .about__overlay {
            margin-top: 80px;
            background: #011627ab;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            h1 {
                font-size: 80px;
                font-weight: 700;
                color: #fff;
            }
        }
    }

    &__mission {
        padding: 70px 0 50px;

        .col-lg-3{
            margin-bottom: 20px;
        }

        &-box {
            border-radius: 10px;
            padding: 25px 25px 0 25px;
            height: 100%;
            box-shadow: 0px 0px 13px 0px rgb(0 0 0 / 22%);
            h3 {
                font-size: 25px;
                margin-bottom: 20px;
                color: rgb(43, 43, 43);
            }

            p {
                font-weight: 400;
                font-size: 15px;
                line-height: 1.8;
                color: rgb(43, 43, 43);
            }
        }
    }

    &__name {
        color: rgb(43, 43, 43);
    }

    @include mq("phone-wide") {
        &__header {
            .about__overlay {
                h1 {
                    font-size: 50px;
                }
            }
        }
    }
}
