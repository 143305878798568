@import "../../styles/variables";
@import "../../styles/mixins";

.contactssection {
    background-color: #d7deee;
    .row {
        overflow: hidden;
    }
    h2 {
        margin: 22px 0;
        color: #2b2b2b;
        font-size: 42px;
    }

    img {
        width: 100%;
        object-fit: cover;
        min-height: 570px;
    }
}
