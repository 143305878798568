@import "./variables";

.button {
    padding: 0;
    color: #75b31e;
    font-weight: 500;
    font-size: 17px;
    position: relative;
    z-index: 1;
    outline: none;
    border: 0;
    background-color: transparent;
    transition: all 0.3s ease;
    border-bottom: 2px solid ;
    margin-bottom: 15px;

    &:hover {
        color: #497808;
        box-shadow: 0 0 0 0 transparent !important;
    }

    

    &:focus-visible {
        box-shadow: 0 0 0 2px #000 !important;
    }

    &:active {
        border: 0;
        transform: scale(0.85);
        transition: all 0.05s;
    }
}
